export const environment = {
  development: true,
  serverUrl: 'https://api.builtdesign.co.in/api/v1',
  templateBuilderUrl: 'https://emailbuilder.builtdesign.co.in',
  awsConfig: {
    region: 'ap-south-1',
    userBucket: 'user-content.builtdesign.in',
    identityPoolId: 'ap-south-1:8b817c85-7867-4d8c-8599-f59264de17ab',
    userPoolId: 'ap-south-1_6wbrhH2nG',
    userPoolWebClientId: '6s6pgp5n07bbbuispkm6270is4',
    analyticsDisabled: false,
  },
  architectUrl: 'https://architect.builtdesign.co.in/',
  recaptcha: {
    siteKey: '6LdAdy8qAAAAAMK8jq3a7ybx6y1tzG0XZFditz8Q',
    secretKey: '6LdAdy8qAAAAANiZa_ZIqHDlMNiYnLuo5Strp9jk',
  },
  env: 'dev',
  sentryDsn: 'https://7b0a9821fc350bdca71caa630d1dd99e@o4507861625995264.ingest.de.sentry.io/4507883247501393',
  firebase: {
    apiKey: 'AIzaSyAE3uh_a4sdHa79MMhGOdB_fg0avykQ7TU',
    authDomain: 'ng-chat-app-e0578.firebaseapp.com',
    databaseURL: 'https://ng-chat-app-e0578-default-rtdb.firebaseio.com',
    projectId: 'ng-chat-app-e0578',
    storageBucket: 'ng-chat-app-e0578.firebasestorage.app',
    messagingSenderId: '253730206971',
    appId: '1:253730206971:web:00f80c27b90180215f2c5e',
    measurementId: 'G-JH5TVNHFNJ',
  },
};
// https://7b0a9821fc350bdca71caa630d1dd99e@o4507861625995264.ingest.de.sentry.io/4507883247501393
