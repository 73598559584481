import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { interval, Observable, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CognitoService } from 'src/app/services/cognito.service';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private serverUrl: string = environment.serverUrl;
  constructor(private _http: HttpClient, private router: Router, private cognitoService: CognitoService) {}

  handleError(error: HttpErrorResponse): Observable<any> {
    if (error?.error.statusCode === 401) {
      setTimeout(() => {
        this.cognitoService.handleSignOut().then((res: any) => {});
        this.router.navigate(['/login']);
        localStorage.clear();
      }, 1000);
    }
    const errorMessage: any = {
      status: error.error.statusCode,
      message: error.error.message?.message || error.error.message,
    };
    throw errorMessage;
  }

  postRequest(endPointUrl: string, data?: any): Observable<any> {
    let url = this.serverUrl + endPointUrl;
    return this._http.post<any>(url, data).pipe(
      map((responseJSON: any) => {
        return responseJSON;
      }),
      catchError(this.handleError.bind(this)),
    );
  }

  putRequest(endPointUrl: string, data: any): Observable<any> {
    let url = this.serverUrl + endPointUrl;
    return this._http.put<any>(url, data).pipe(
      map((responseJSON: any) => {
        return responseJSON;
      }),
      catchError(this.handleError.bind(this)),
    );
  }

  patchRequest(endPointUrl: string, data?: any): Observable<any> {
    let url = this.serverUrl + endPointUrl;
    return this._http.patch<any>(url, data).pipe(
      map((responseJSON: any) => {
        return responseJSON;
      }),
      catchError(this.handleError.bind(this)),
    );
  }

  getRequest(endPointUrl: string, options?: any): Observable<any> {
    let url = this.serverUrl + endPointUrl;
    return this._http.get<any>(url, options).pipe(
      map((responseJSON: any) => {
        // console.log(responseJSON);
        if (Array.isArray(responseJSON?.data?.data)) {
          return responseJSON.data;
        }
        return responseJSON;
      }),
      catchError(this.handleError.bind(this)),
    );
  }

  deleteRequest(endPointUrl: string, data?: any): Observable<any> {
    let url = this.serverUrl + endPointUrl;
    if (data) {
      const options = {
        body: data,
      };
      return this._http.delete<any>(url, options).pipe(
        map((responseJSON: any) => {
          return responseJSON;
        }),
        catchError(this.handleError.bind(this)),
      );
    } else {
      return this._http.delete<any>(url).pipe(
        map((responseJSON: any) => {
          return responseJSON;
        }),
        catchError(this.handleError.bind(this)),
      );
    }
  }

  startPolling(endPointUrl: string): Observable<any> {
    return interval(10000).pipe(switchMap(() => this.getRequest(endPointUrl)));
  }
}
