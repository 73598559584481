export enum MetadataTypeEnum {
  SKILLS = 1,
  WORK_CATEGORY = 2,
  PROJECT_TYPE = 3,
  SOFTWARE_SKILLS = 4,
}

export enum badegesEnum {
  Onboarding = 1,
  Application = 2,
  Worker = 3,
  Professional = 4,
}
export enum JobStatus {
  INITIATED = 1,
  UNDER_REVIEW = 2,
  APPROVED = 3,
  IN_PROGRESS = 4,
  COMPLETED = 5,
  DECLINED = 6,
  HOLD = 7,
}

export enum BidStatus {
  UNDER_REVIEW = 1,
  ADMIN_APPROVED = 2,
  ADMIN_REJECTED = 3,
  APPROVED = 4,
  DECLINED = 5,
  HOLD = 6,
}

// export enum PaymentGatewayStatus {
//   PENDING = 1,
//   COMPLETED = 2,
//   FAILED = 3,
//   REFUNDED = 4,
//   CANCELED = 5,
//   IN_PROGRESS = 6,
//   PARTIALLY_REFUNDED = 7,
// }

export enum CoaEnum {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

export enum ServiceType {
  ANCILLARY_SERVICE = 'Ancillary Service',
  DESIGN_SERVICE = 'Design Service',
}

export enum MetadataStatusEnum {
  PENDING = 1,
  APPROVED = 2,
  DISAPPROVE = 3,
}

export enum SupportStatusEnum {
  OPEN = 1,
  IN_PROGRESS = 2,
  CLOSED = 3,
}

export enum SupportType {
  JOB_RELATED = 'Job Related',
  ACCOUNT_RELATED = 'Account Related',
  PAYMENT_RELATED = 'Payment Related',
  OTHERS = 'Others',
}

export enum SubmissionStatusEnum {
  UNDER_REVIEW = 1,
  ADMIN_APPROVED = 2,
  CLIENT_APPROVED = 3,
  CLIENT_REJECTED = 4,
  DECLINED = 5,
}

export enum FinalSubmissionStatusEnum {
  UNDER_REVIEW = 1,
  ADMIN_APPROVED = 2,
  CLIENT_APPROVED = 3,
  CLIENT_REJECTED = 4,
  REVISE = 5,
  DECLINED = 6,
}

export enum PaymentMethod {
  UPI = 'upi',
  CARD = 'card',
  NET_BANKING = 'netbanking',
  WALLET = 'wallet',
  EMI = 'emi',
}

export enum PaymentGatewayStatus {
  CREATED = 'created',
  AUTHORIZED = 'authorized',
  CAPTURED = 'captured',
  FAILED = 'failed',
  REFUNDED = 'refunded',
  CANCELED = 'canceled',
}

export enum CampaignStatus {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
  PAUSED = 'paused',
}
