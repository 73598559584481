export class ApiConfig {
  static readonly urlConstant = {
    login: '/auth/admin/login',
    changePassword: '/auth/admin/change-password',
    metaData: '/utils/metadata',
    user: '/user',
    userProfile: '/user/profile',
    pendingRequest: '/user/pending-request',
    coaStatusUpdate: '/user/coa-status-update',
    coaStats: '/user/coa-stats',
    userStats: '/user/user-stats',
    clientStats: '/user-dashboard/client-stats',
    jobs: '/jobs',
    jobsStats: '/jobs/jobs-stats',
    jobsStatusUpdate: '/jobs/job-status-update',
    bid: '/bid',
    bidByJob: '/bid/get-by-job',
    bidStatus: '/bid/status',
    dashboardStats: '/user-dashboard/admin-dashboard',
    support: '/support',
    jobsAmountUpdate: '/jobs/amount-update',
    bidSubmissionStatus: '/bid/submission-status',
    client: '/client',
    transactions: '/payments/razorpay/transactions',
    transactionStats: '/payments/transactions/stats',
    deleteJobs: '/jobs/delete-job',
    notification: '/notification',
    forum: '/forum',
    campaign: '/campaign',
  };
}
